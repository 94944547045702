import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import './../styles/styles.scss'

let script = '';
if (typeof window !== `undefined`) {
  script = `if ( window != top ) {
    var main = document.getElementsByTagName('MAIN');
    for (var i=0; i<main.length; i++) {
      main[i].parentNode.removeChild(main[i]);
    }

    var body = document.querySelector('body');
    var header = document.querySelector('.site-header');
    var footer = document.querySelector('.site-footer');

    header.parentNode.removeChild(header);
    footer.parentNode.removeChild(footer);
    
    var wrapper = document.createElement('div');
    wrapper.classList = 'iframe-message container';
    var title = document.createElement('h1');
    var message = document.createElement('p');
    var linkMessage = document.createElement('h2');
    var link = document.createElement('button');
    
    link.onclick = function() {
      var copyInput = document.createElement('input');
      copyInput.value = window.location;
      body.appendChild(copyInput);
      copyInput.select();
      document.execCommand('copy');
      copyInput.parentNode.removeChild(copyInput);

      var copiedMessage = document.createElement('h3');
      copiedMessage.innerHTML = "URL Copied - Thanks! Now paste it in your address bar to view the post :)";
      wrapper.appendChild(copiedMessage);
    }

    title.innerHTML = "It looks like this content is being viewed in an iframe on someone else's website, without permission.";
    message.innerHTML = "Since this negatively impacts my site SEO and appears to Google as duplicate content I have removed the content from this page when it is being viewed within an iframe.";
    linkMessage.innerHTML = "Please click here to copy the post's real URL to your clipboard, then paste it in your address bar to view the content:";
    link.innerHTML = "Click to copy the real URL!";

    wrapper.appendChild(title);
    wrapper.appendChild(message);
    wrapper.appendChild(linkMessage);
    wrapper.appendChild(link);
    body.appendChild(wrapper);


    var messageToIframerWrapper = document.createElement('div');
    messageToIframerWrapper.classList = 'message-to-iframer container';

    var messageToIframer_1 = document.createElement('p');
    messageToIframer_1.innerHTML = "If you are the person who implemented this iframe, and you are genuinely trying to link out to my content from your site, you should really get in touch first, and you should definitely never use iframes to do this. Get in touch and we'll figure out a way you can link to my content on your site without it negatively affecting either of us.";
    messageToIframerWrapper.appendChild(messageToIframer_1);

    var messageToIframer_2 = document.createElement('p');
    messageToIframer_2.innerHTML = "However if you're just stealing content, I hope this message serves as a warning that by iframing someone else's content, you're allowing them to add anything they want under your URLs.";
    messageToIframerWrapper.appendChild(messageToIframer_2);

    var gif = document.createElement('img');
    gif.src = 'https://media.giphy.com/media/zaCojXv2S01zy/giphy.gif';
    messageToIframerWrapper.appendChild(gif);

    body.appendChild(messageToIframerWrapper);
  }`;
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'A web and WordPress development blog' },
            { name: 'keywords', content: 'Web development, WordPress, JavaScript, JS, CSS, Front-end' },
            { name: 'google-site-verification', content: 'Obu2vyEhhlX2zHl6T78WtU3ilqm884ghT4n_UugCxt8' }
          ]}
        >
          <html lang="en" />
          <script>{script}</script>
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main className="container">
          {children}
        </main>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
